<template>
  <div class="refund card row mt-2 p-1">
    <div class="col-md-12">
      <h4 class="h4 text-center p-1">Refund Google Purchased</h4>
      <div class="form-group row">
        <label for="" class="col-sm-2"> Search Book <br /> </label>
        <div class="col-sm-7">
          <input
            type="text"
            name="searcg"
            id=""
            @input="searchBook"
            class="form-control"
            placeholder="GPA..."
          />
          <span v-if="typing"> <em> Mencari .. </em> </span>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-auto">
          Total <strong>{{ formatPriceBasic(totalRows) }}</strong>
        </div>
        <div class="col-md-auto justify-content-end">
          <span @click="refresh()">
            <i class="fa fa-refresh"></i>
          </span>
        </div>
      </div>
      <b-table
        sticky-header="500px"
        show-empty
        :busy="isLoading"
        :fields="fields"
        hover
        responsive="xl"
        :items="items"
      >
        <template #cell(no)="row">
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </template>
        <template #cell(id)="row">
          <router-link
            :to="{ path: '/order/detail?orderid=' + row.item.id }"
            class="link"
          >
            {{ row.item.id }}
          </router-link>
        </template>
        <template #cell(name)="row">
          {{ row.item.name }}
        </template>
        <template #cell(refund_date)="row">
          {{ formatDate(row.item.voided_timemillis) }}
        </template>
        <template #cell(final_price)="row">
          {{
            formatPrice(row.item.final_price, row.item.currency_code || 'IDR')
          }}
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-dark my-2">
            <b-spinner
              class="align-middle"
              variant="dark"
              type="grow"
              label="Loading .."
            >
            </b-spinner>
            Loading ..
          </div>
        </template>
      </b-table>
    </div>
    <b-row class="justify-content-start ml-1">
      <b-col lg="3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'RefundGoogle',
  data() {
    return {
      fields: [
        { key: 'No', label: 'No' },
        { key: 'id', label: 'Order Key', sortable: true },
        { key: 'reference_id', label: 'IAB' },
        { key: 'name', label: 'offer name' },
        { key: 'refund_date', label: 'Refund Date' },
        { key: 'final_price', label: 'Refund Amount' },
        { key: 'voided_reason', label: 'Refund Reason' },
        { key: 'voided_source', label: 'Refund Source' },
      ],
      currentPage: 1,
      perPage: 100,
      sortBy: 'id',
      message: '',
      typing: null,
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.refund.items,
      isLoading: (state) => state.refund.isLoading,
      isError: (state) => state.refund.isError,
      totalRows: (state) => state.refund.totalRows,
      errorMessage: (state) => state.refund.ErrorMessage,
    }),
  },
  methods: {
    ...mapActions('refund', ['fetchRefundsGoogle']),
    formatDate(tgl) {
      return moment.unix(tgl / 1000).format('D MMM YYYY HH:mm:ss');
    },
    refresh() {
      this.fetchRefundsGoogle({
        page: this.currentPage,
        limit: this.perPage,
        sortBy: 'id',
        sortDesc: true,
      });
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    formatPrice(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }
        return formatter.format(value);
      }
      return '0';
    },
    searchBook(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        if (this.message.length > 0) {
          this.fetchRefundsGoogle({
            q: event.target.value,
            page: 1,
            limit: this.perPage,
          });
        } else {
          this.fetchRefundsGoogle({
            page: 1,
            limit: this.perPage,
            sortBy: 'id',
            sortDesc: true,
          });
        }
      }, 800);
    },
  },
  watch: {
    currentPage(val) {
      this.fetchRefundsGoogle({
        page: val,
        limit: this.perPage,
        sortBy: 'id',
        sortDesc: true,
      });
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Google Purchase Voided | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  mounted() {
    this.fetchRefundsGoogle({
      page: this.currentPage,
      limit: this.perPage,
      sortBy: 'id',
      sortDesc: true,
    });
  },
};
</script>
<style scoped>
.refund {
  font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
}
::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}
</style>
