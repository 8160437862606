var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "refund card row mt-2 p-1"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('h4', {
    staticClass: "h4 text-center p-1"
  }, [_vm._v("Refund Google Purchased")]), _c('div', {
    staticClass: "form-group row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-sm-7"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "searcg",
      "id": "",
      "placeholder": "GPA..."
    },
    on: {
      "input": _vm.searchBook
    }
  }), _vm.typing ? _c('span', [_c('em', [_vm._v(" Mencari .. ")])]) : _vm._e()])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-md-auto"
  }, [_vm._v(" Total "), _c('strong', [_vm._v(_vm._s(_vm.formatPriceBasic(_vm.totalRows)))])]), _c('div', {
    staticClass: "col-md-auto justify-content-end"
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.refresh();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-refresh"
  })])])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "show-empty": "",
      "busy": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "responsive": "xl",
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + row.index + 1) + " ")];
      }
    }, {
      key: "cell(id)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              path: '/order/detail?orderid=' + row.item.id
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.id) + " ")])];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.name) + " ")];
      }
    }, {
      key: "cell(refund_date)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(row.item.voided_timemillis)) + " ")];
      }
    }, {
      key: "cell(final_price)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.final_price, row.item.currency_code || 'IDR')) + " ")];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "col-sm-2",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Search Book "), _c('br')]);
}]

export { render, staticRenderFns }